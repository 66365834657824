<template>
  <div id="register-form">
    <aside v-if="type === 'login'">
      <figure>
        <a href="/">
          <b-img :src="logoPath" fluid width="150"></b-img>
        </a>
      </figure>
      <b-row>
        <b-col md="12" class="text-center p-2">
          <h3>Autentificare</h3>
        </b-col>
      </b-row>
      <form autocomplete="off">
        <div class="form-group">
          <b-input type="email" v-model="email" :placeholder="'Adresa de email'"></b-input>
          <i class="icon_mail_alt"></i>
        </div>
        <div class="form-group">
          <div class="hideShowPassword-wrapper" style="position: relative; display: block; vertical-align: baseline; margin: 0px;">
            <b-input :type="passwordType" v-model="password" :placeholder="'Parola'"></b-input>
            <b-button
                type="button"
                class="my-toggle hideShowPassword-toggle-show"
            >
            </b-button>
          </div>
          <i class="icon_lock_alt"></i>
        </div>
        <div class="clearfix add_bottom_15">
          <div class="float-right">
            <b-link @click="type = 'reset'">Ai uitat parola?</b-link>
          </div>
        </div>
        <b-button @click="login" class="btn_1 gradient full-width">
          Intra in cont
        </b-button>
        <div class="text-center mt-2">
          Nu ai cont? <strong>
          <b-link @click="type = 'register'">Inregistrare</b-link>
          </strong>
        </div>
      </form>
      <b-row>
        <b-col md="12">
          <v-facebook-login
              style="width: 100%"
              v-model="fbModel"
              @sdk-init="handleSdkInit"
              app-id="1049144042578353"
              @login="getUserData"
              @logout="logout"
          >
            <span slot="login">Autentificare cu Facebook</span>
            <span slot="working">Asteptati...</span>
          </v-facebook-login>
        </b-col>
      </b-row>
    </aside>
    <aside v-if="type === 'register'">
      <figure>
        <a href="/">
          <b-img :src="logoPath" fluid width="150"></b-img>
        </a>
      </figure>
      <!--
      <div class="access_social">
        <a href="#0" class="social_bt facebook">Login with Facebook</a>
        <a href="#0" class="social_bt google">Login with Google</a>
      </div>

      <div class="divider"><span>Or</span></div>
      -->
      <b-row>
        <b-col md="12" class="text-center p-2">
          <h3>Inregistrare</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="form-group">
            <label>Email</label>
            <b-input type="text" v-model="email" placeholder="Email (obligatoriu)"></b-input>
          </div>
          <div class="form-group">
            <label>Parola</label>
            <b-input type="password" v-model="password" placeholder="Parola (obligatoriu)"></b-input>
          </div>
          <div class="form-group">
            <label>Confirmare Parola</label>
            <b-input type="password" v-model="c_password" placeholder="Confirmare Parola (obligatoriu)"></b-input>
          </div>
          <div class="form-group">
            <label>Nume</label>
            <b-input type="text" v-model="lastName" placeholder="Nume (obligatoriu)"></b-input>
          </div>
          <div class="form-group">
            <label>Prenume</label>
            <b-input type="text" v-model="firstName" placeholder="Prenume (obligatoriu)"></b-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="clearfix add_bottom_15">
            <div class="float-right">
              <b-link @click="type = 'reset'">Ai uitat parola?</b-link>
            </div>
          </div>
          <b-button @click="register" class="btn_1 gradient full-width">
            Inregistrare
          </b-button>
          <div class="text-center mt-2">
            Esti deja client? <strong>
            <b-link @click="type = 'login'">Autentificare</b-link>
          </strong>
          </div>
        </b-col>
      </b-row>
    </aside>
    <aside v-if="type === 'reset'">
      <figure>
        <a href="/">
          <b-img :src="logoPath" fluid width="150"></b-img>
        </a>
      </figure>
      <!--
      <div class="access_social">
        <a href="#0" class="social_bt facebook">Login with Facebook</a>
        <a href="#0" class="social_bt google">Login with Google</a>
      </div>

      <div class="divider"><span>Or</span></div>
      -->
      <b-row>
        <b-col md="12" class="text-center p-2">
          <h3>Resetare parola</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="form-group">
            <label>Email</label>
            <b-input type="text" v-model="email" placeholder="Email (obligatoriu)"></b-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button @click="reset" class="btn_1 gradient full-width">
            Resetare parola
          </b-button>
          <div class="text-center mt-2">
            Esti deja client? <strong>
            <b-link @click="type = 'login'">Autentificare</b-link>
          </strong>
          </div>
        </b-col>
      </b-row>
    </aside>
  </div>
</template>

<script>
import Auth from '@/components/auth'
import { bus } from "../../main";
import VFacebookLogin from 'vue-facebook-login-component'

export default {
  data() {
    return {
      FB: {},
      fbModel: {},
      scope: {},
      type: 'login',
      email: '',
      password: '',
      c_password: '',
      passwordType: 'password',
      firstName: '',
      lastName: '',
      street: '',
      city: '',
      zip: '',
      phone: '',
      logoPath: process.env.VUE_APP_LOGO_PATH
    }
  },
  components: {
    VFacebookLogin
  },
  methods: {
    async login () {
      let authData = {
        email: this.email,
        password: this.password
      }
      let loginResponse = await Auth.login(authData)
      if (loginResponse.success) {
        localStorage.setItem('token', loginResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user_data))
        bus.$emit('loggedIn', loginResponse.data.user_data)
        this.$emit('close')
      } else {
        this.$toastr.e('Datele de autentificare nu corespund');
      }
    },
    logout() {
      Auth.logout();
    },
    async register () {
      let registerData = {
        email: this.email,
        password: this.password,
        c_password: this.c_password,
        first_name: this.firstName,
        last_name: this.lastName,
      }
      let registerResponse = await Auth.register(registerData)
      if (registerResponse.success) {
        localStorage.setItem('token', registerResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(registerResponse.data.user_data))
        bus.$emit('loggedIn', registerResponse.data.user_data)
        this.$emit('close')
      } else {
        this.$toastr.e('Va rugsam sa completati toate campurile obligatorii');
      }
    },
    reset() {
      Auth.resetPassword(this.email).then(response => {
        this.$swal(
            'Verificare Email',
            'Pentru a putea reseta parola va rugam sa accesati link-ul primit prin E-Mail.',
            'success'
        )
      }).catch(error => {
      })
    },
    handleSdkInit({ FB, scope }) {
      this.FB = FB
      this.scope = scope
    },
    getUserData() {
      this.FB.login(response => {
        if (response.authResponse) {
          this.FB.api('/me?fields=id,name,email', async response => {
            //Check login
            let authData = {
              email: response.email,
              name: response.name,
              password: response.id,
              facebook: true
            }
            let loginResponse = await Auth.login(authData)
            if (loginResponse.success) {
              localStorage.setItem('token', loginResponse.data.token)
              localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user_data))
              bus.$emit('loggedIn', loginResponse.data.user_data)
              this.$emit('close')
            } else {
              //Check register
              let fbName = response.name.split(" ");
              let registerData = {
                email: response.email,
                password: response.id,
                c_password: response.id,
                first_name: fbName[0],
                last_name: fbName[1],
              }
              let registerResponse = await Auth.register(registerData)
              if (registerResponse.success) {
                localStorage.setItem('token', registerResponse.data.token)
                localStorage.setItem('userInfo', JSON.stringify(registerResponse.data.user_data))
                bus.$emit('loggedIn', registerResponse.data.user_data)
                this.$emit('close')
              }
            }
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      });
    },
  },
  mounted() {
    bus.$on("loggedOut", () => {
      this.FB.logout();
    })
  }
}
</script>